import React, { useRef, useState } from 'react';
import {
  Alignments,
  Button,
  Icons,
  Sentiments,
  Sizes,
  Variants
} from '@sede-x/shell-ds-react-framework';
import {
  Field,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikValues
} from 'formik';
import TextArea from 'components/FormCmponents/TextArea';
import { TollSpecficEndpoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { queryClient } from 'react-query';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import { StyledModal } from './styles';
import { ParameterDto } from './types';
import { TollIdMap } from './AccordionContent';
import { identifier } from './TollSpecific';

interface ParameterDeleteProps {
  parameter: ParameterDto;
  type: string;
  id: string;
  disabled?: boolean;
}

const ParameterDelete: React.FC<ParameterDeleteProps> = ({
  parameter,
  type,
  id,
  disabled
}) => {
  const [open, setOpen] = useState(false);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleClose = () => {
    setOpen(false);
    formRef.current?.resetForm();
  };

  const handleDelete = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;

    const payload = {
      ...values,
      [TollIdMap[type]]: parameter[TollIdMap[type] as keyof ParameterDto],
      [identifier[type]]: parameter[identifier[type] as keyof ParameterDto]
    };

    customerInstance
      .post(TollSpecficEndpoints.delete[type], payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['toll-specific', id, type]
        });

        queryClient.invalidateQueries({
          queryKey: ['Comments', id, type]
        });
        successDialog('', 'Successfully Saved.');
        handleClose();
      })
      .catch((error) => {
        const message = 'Failed to delete parameter.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Button
        icon={<Icons.TrashClear />}
        size="small"
        iconOnly
        sentiment={Sentiments.Negative}
        variant={Variants.Outlined}
        onClick={() => setOpen(true)}
        data-testid={`delete-${parameter?.parameter}`}
        disabled={disabled}
        hidden={disabled}
      />
      <Formik<FormikValues>
        initialValues={{
          message: `Remove STV: ${parameter?.parameter} ${
            parameter?.value ?? ''
          }`
        }}
        enableReinitialize
        onSubmit={handleDelete}
        validate={(values) => {
          if (!values.message) {
            return { message: 'This is a mandatory field' };
          }
          return {};
        }}
        innerRef={formRef}
      >
        {({ handleSubmit, values }) => (
          <StyledModal
            title="Comment"
            size={Sizes.Small}
            maskClosable={false}
            mask
            contentScrollable
            actionsAlignment={Alignments.Right}
            centered
            open={open}
            onClose={handleClose}
            zIndex={3}
            actions={[
              {
                label: 'CANCEL',
                action: () => {
                  handleClose();
                },
                props: {
                  variant: Variants.Outlined,
                  icon: <Icons.CrossCircleSolid />
                }
              },
              {
                label: 'SAVE',
                action: () => {
                  handleSubmit();
                },
                props: {
                  icon: <Icons.Save />,
                  disabled: !values.message
                }
              }
            ]}
          >
            <Field
              id="message"
              name="message"
              placeholder="Type here"
              component={TextArea}
              rows={6}
              resize="none"
            />
          </StyledModal>
        )}
      </Formik>
    </div>
  );
};

export default ParameterDelete;
